<template>
  <van-row class="searchTrain">
    <!--头部日历-->
    <van-sticky>
      <div style="background: linear-gradient(to right, rgb(75, 176, 255), rgb(44, 80, 212));">
        <SliderDate @changeDate="calendarConfirm" :curDate="query.depDate" @open="show_calendar = true" :start="startDate"/>
        <!-- :prices="[1300,900,950,850,700,900,800,850,1400,1500,1300,900,950,850,700,900,800,850,1400,1500,1300,900,950,850,700,900,800,850,1400,1500]" -->
      </div>
    </van-sticky>

    <!-- 插槽 -->
    <slot name="header"></slot>

    <!-- 列表，下拉刷新已注释 -->
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <van-skeleton :row-width="skeletonArr" :row="4*7" :loading="isLoading" class="skeleton-cont" :style="isLoading?'height:calc(100vh - 120px)':''">
      <BaseCol v-if="showlist.supplierNum>0||0 in list" @chartClick="filterDate" :data="chartData" ref="chart" />
      <!--列表-->
      <div class="listoutline">
        <template>
          <List ref="child" :finish="listfinish" :list="list" @childClick="chooseTicket($event)" />
          <van-empty v-show="!(0 in list)" :description="this.listfinish?'暂无航班信息，请尝试更换机场。':'正在尝试获取其他供应商信息，请稍后。。。'" />
        </template>
      </div>
    </van-skeleton>
    <!-- </van-pull-refresh> -->
    <div class="gap"></div>

    <!--筛选bar-->
    <van-tabbar style="border:1px solid #eee" v-model="activenav" safe-area-inset-bottom>
      <van-tabbar-item name="early" class="navicon" icon-prefix="icon" icon="naozhong" replace @click="tabbar_item_handle_1()">提早</van-tabbar-item>
      <van-tabbar-item name="money" class="navicon" icon-prefix="icon" icon="feiyongbaohan" replace @click="tabbar_item_handle_2()">省钱</van-tabbar-item>
      <van-tabbar-item name="time" class="navicon" icon-prefix="icon" icon="shanguangdengdakai" replace @click="tabbar_item_handle_3()">省时</van-tabbar-item>
      <van-tabbar-item name="filter" class="navicon" icon-prefix="icon" icon="shaixuan" replace :dot="0 in ticket_filter_activeIds" @click="ticket_filter_show = true">筛选</van-tabbar-item>
    </van-tabbar>

    <!--日期选择-->
    <van-calendar v-model="show_calendar" color="#198BDA" :show-confirm="false" confirm-disabled-text="请选择出发时间" :default-date="new Date(query.depDate)" :minDate="startDate?new Date(startDate):new Date()" position="right" :round="false" @confirm="calendarConfirm" />

    <!--筛选选项卡-->
    <van-action-sheet v-model="ticket_filter_show" title="筛选" @cancel="tabbar_filter_cancel" safe-area-inset-bottom :close-on-click-overlay="false">
      <div class="tab_bar_filter_content">
        <van-tree-select :items="ticket_filter_items" :active-id.sync="ticket_filter_activeIds" :main-active-index.sync="ticket_filter_activeIndex" />
      </div>
      <van-row type="flex" justify="space-around" align="center" style="margin-bottom: 10px">
        <van-button style="width: 100px" plain type="info" @click="tabbar_filter_clear">清空</van-button>
        <van-button style="width: 100px" type="info" @click="tabbar_filter_confirm">确认</van-button>
      </van-row>
    </van-action-sheet>

    <!--加载loading-->
    <!-- <LoadingCircle :show="isLoading" /> -->
  </van-row>
</template>

<script>
import { init } from "@/services/list";
import List from "./TicketItem";
import BaseCol from "@/components/chart/BaseCol";
import SliderDate from "@/components/SliderDate";
import LoadingCircle from "@/components/LoadingCircle";
import { AircraftServiceProvider } from "@/common/config";
import { SortForChart } from "@/services/aircraft";
import SkeletonArr from "@/common/Skeleton.js";
import { ToTop } from "@/services/utils";

export default {
  name: "SearchTrain",
  props: {
    query: Object,
    isNormal: {
      default: true
    },
    startDate:{
      default: null
    }
  },
  components: { List, SliderDate, BaseCol, LoadingCircle },
  computed: {
    // 筛选项目，通过config.js得出
    ticket_filter_items: function () {
      return [
        {
          text: "服务商",
          children: AircraftServiceProvider.map(item => {
            return {
              text: item,
              id: item
            };
          })
        }
      ];
    },
  },
  activated() {
    let corps = [] // 当前列表有哪些供应商
    this.list.forEach(element => {
      corps.push(element.corp)
    });
    let corpLen = [...new Set(corps)].length
    // ids筛选条件中有哪些供应商，ticket_filter_activeIds这个里面目前仅有已选供应商，但以后可能有其他筛选条件
    let ids = this.ticket_filter_activeIds 
    ids = ids.filter(element=>{
      return AircraftServiceProvider.includes(element)
    })
    if(!(0 in ids)) ids = AircraftServiceProvider // 如果没筛选条件，默认全选
    if (corpLen < ids.length || !(0 in this.showlist.list)) this.onRefresh()
    this.chartData = SortForChart(this.showlist.list)
  },
  data() {
    return {
      canReloadChar: true,
      chartData: null,
      goticketInfo: {}, //记录去程票
      activenav: "early", // 底部四个按钮导航 变蓝
      isLoading: true,
      show_calendar: false,
      ticket_filter_show: false, // 筛选bar中的详细筛选
      ticket_filter_activeIds: [],
      ticket_filter_activeIndex: 0,
      list: [], // 用来展示的列表，showlist.list改变会影响list。反之不成立
      listfinish: false, // 列表是否加载完成
      showlist: init(), // 缓存对象
      skeletonArr: SkeletonArr, // 骨架数据
      filterTime: undefined // 子组件筛选日期
    };
  },
  methods: {
    // 清空筛选和日期条件（包含子组件）
    clearFilter() {
      this.ticket_filter_activeIds = [];
      this.activenav = "";
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.isLoading = true
        this.showlist.resetList();
        this.showlist.update("aircraft", this.query, this.isNormal);
      }, 500);
    },

    // 日历选择日期
    calendarConfirm(date) {
      this.$emit("updateDate", date);
      this.show_calendar = false;
    },
    // 下方三个筛选
    tabbar_item_handle_1() {
      this.canReloadChar = false;
      this.showlist.sort((a, b) => {
        let a_time = parseInt(a.departTime.replace(":", ""));
        let b_time = parseInt(b.departTime.replace(":", ""));
        return parseInt(a_time) - parseInt(b_time);
      });
      ToTop()
    },
    tabbar_item_handle_2() {
      this.canReloadChar = false;
      this.showlist.sort((a, b) => {
        return (
          parseInt(a.canBuyMinPrice || 9999) -
          parseInt(b.canBuyMinPrice || 9999)
        );
      });
      ToTop()
    },
    tabbar_item_handle_3() {
      this.canReloadChar = false;
      this.showlist.sort((a, b) => {
        return (
          a.runTime.replace(/[^0-9]/gi, "") - b.runTime.replace(/[^0-9]/gi, "")
        );
      });
      ToTop()
    },

    //筛选filter 取消所有选择
    tabbar_filter_cancel() {
      this.ticket_filter_activeIds = [];
      this.tabbar_filter_confirm();
    },
    //筛选filter 取消所有选择
    tabbar_filter_clear() {
      this.ticket_filter_activeIds = [];
    },
    //筛选filter 确认
    tabbar_filter_confirm() {
      let arr = [];
      this.showlist.list.forEach(element => {
        let f = true
        if (this.ticket_filter_activeIds.length > 0) {
          if (this.ticket_filter_activeIds.includes(element.corp))
            f = true
          else f = false
        }
        if (this.filterTime) {
          if (
            Math.floor((parseInt(this.filterTime.split(":")[0]) - 6) / 3) ==
            Math.floor((parseInt(element.departTime.split(":")[0]) - 6) / 3)
          )
            f = f && true
          else f = f && false
        }
        if (f) arr.push(element);
      })
      this.list = arr;
      this.ticket_filter_show = false;
    },

    //❤️❤️核心：选好票啦~！❤️❤️
    async chooseTicket(ticketInfo) {
      let result = ticketInfo;
      // 普通购票传数组 在订单页比价
      if (this.isNormal) {
        let arr = [];
        // 下方统计同机型舱位，并去除筛选舱位
        let filterCorp = this.ticket_filter_activeIds.filter(e =>
          AircraftServiceProvider.includes(e)
        );
        this.showlist.list.forEach(ticket => {
          if (
            ticketInfo.flightNo == ticket.flightNo &&
            (filterCorp.length == 0 || filterCorp.includes(ticket.corp))
          )
            arr.push(ticket);
        });
        result = arr
      }
      this.$emit("chooseTicket", result, {
        corp: ticketInfo.corp,
      });
    },

    // 点击图表筛选列表
    filterDate(data) {
      this.filterTime = data ? data.time : undefined;
      this.tabbar_filter_confirm();
    },

  },

  watch: {
    "showlist.check": {
      handler: function () {
        this.list = this.showlist.list;
        if (this.canReloadChar)
          this.chartData = SortForChart(this.showlist.list);
        this.canReloadChar = true;
        this.tabbar_filter_confirm();
        if (this.$refs.hasOwnProperty("child") && this.$refs.child)
          this.$refs.child.check();
        if (this.showlist.supplierNum > 0) this.isLoading = false;
        else this.isLoading = true;
        // 更新列表加载状态（加载完没）
        this.listfinish = this.showlist.supplierNum >= AircraftServiceProvider.length;
      }
    },
    "query.date": {
      handler: function () {
        this.isLoading = true;
        this.clearFilter();
        this.showlist.update("aircraft", this.query, this.isNormal);
      },
      deep: true
    }
  }
};
</script>

<style lang="less" src="@/styles/searchCommon.less" scoped></style>
<style scoped>
.listoutline {
  min-height: calc(100vh - 250px);
}
.bigTitle {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0.4rem;
  background: #e6ffdf;
  color: #3da73b;
}
</style>