/** 火车、机票、酒店的 列表缓存
 *  考虑以下几个问题....例如发送'a''b''c'请求
 *  1、接到a回应之后渲染页面，若用户在b c 请求响应之前再次下拉刷新
 *  2、接到a回应之后渲染页面，若用户在b c 请求响应之前修改出发日期
 *  3、往返购票，接到a回应之后渲染页面，若用户在b c 请求响应之前选择去程
 *  4、用户乱点1212211123121乱点
 * 
 *  提供外部得方法：
 *  resetList()  清空缓存，需要手动清，该类不会主动清空缓存（不光刷新，还有上滑加载更多）
 *  update()    更新当天列表，参数参考下方注释
 *  sort(cb())  排序
 *  
 *  提供外部的属性：
 *  list： 可以渲染的数组
 *  supplier: 那些供应商已经返回了
 *  check：这个东西变化说明列表更新了（成功失败都会触发）
 */
import { TrainServiceProvider, AircraftServiceProvider } from "@/common/config"
import { DiffDate } from "@/services/date"
import moment from "moment";
import { getTrainTicket } from "@/api/train"
import { getFlightTicket, getChangeFlightTicket } from "@/api/aircraft"


class CommonList {
    _tempList = {
        // date：{ 日期做key
        // supplier[] 厂商，例如数组中已存在"大唐"进来的就不要加入list了，不要最新要最快
        // supplierNum 厂商数量
        // list 列表
        // }
    }
    date = ""   // 缓存的key
    check = 0   // 更新数组就修改一下它，不然监听不到

    // list 是提供给外部 可以直接用来展示的列表
    set list(newArr) {
        return console.error('list是只读变量,为保证缓存稳定,请使用update或者sort函数来修改它')
    }
    get list() {
        if (this._tempList.hasOwnProperty(this.date) && this._tempList[this.date].hasOwnProperty('list'))
            return this._tempList[this.date].list
        return []
    }
    get supplier() {
        if (this._tempList.hasOwnProperty(this.date) && this._tempList[this.date].hasOwnProperty('list'))
            return this._tempList[this.date].supplier
        return []
    }
    get supplierNum() {
        if (this._tempList.hasOwnProperty(this.date) && this._tempList[this.date].hasOwnProperty('list'))
            return this._tempList[this.date].supplierNum
        return 0
    }

    // _templist的set函数（核心，维护缓存）
    setTempList(newList, key = this.date, corp) {
        // 如果缓存对象中没有key
        if (!this._tempList.hasOwnProperty(key) || !this._tempList[key].hasOwnProperty('list')) {
            this._tempList[key] = {
                supplier: [corp],
                supplierNum: 1,
                // count: 0,
                list: newList
            }
        }
        else { // 缓存中有key但是key中没有这个供应商，追加
            if (!this._tempList[key].supplier.includes(corp)) {
                this._tempList[key].supplier.push(corp)
                this._tempList[key].supplierNum++
                if (0 in newList) this._tempList[key].list.push(...newList)
            }
        }
        this.check++
    }

    /**
     * 更新缓存
     * @param {string} type aircraft:飞机  train：火车  hotel：酒店
     * @param {Object} query ajax查询条件 改签时需要指定corp。其余情况自动分发
     * @param {Bollean} isNormal 是否正常购票（改签）
     */
    update(type, query, isNormal = true) {
        return new Promise((resolve, reject) => {
            let date = (query.depDate || query.fromDate).toString()
            let supplier = [] // 下面switch获取供应商，之后再循环中获取这些供应商得列表
            switch (type) {
                case 'aircraft':
                    supplier = AircraftServiceProvider
                    break;
                case 'train':
                    supplier = TrainServiceProvider
                    break
            }
            if (this.date != date && this._tempList.hasOwnProperty(date) && this._tempList[date].supplier.length == supplier.length) {
                this.date = date
                setTimeout(() => {
                    this.check++
                    resolve(true)
                }, 200);
            }
            else {
                this.date = date
                supplier.forEach(async (corp) => {
                    let key = date // 记录一下key
                    let result
                    switch (type) {
                        case 'aircraft':
                            // 目前只在改签中携带指定供应商
                            if (!isNormal && query.corp && query.corp == corp) result = await fetchChangeAircraftList(date, JSON.stringify(query), corp)
                            if (isNormal) {
                                query.corp = corp
                                result = await fetchAircraftList(date, JSON.stringify(query), corp)
                            }
                            result = result || []
                            break;
                        case 'train':
                            query.corp = corp
                            result = await fechTrainList(date, JSON.stringify(query), corp)
                            break;
                    }
                    this.setTempList(result, key, corp)
                    resolve(true)
                })
            }
        })
    }

    // 排序，控制层定制排序方法，OrganizeAircraftList再分离一下无票的机票
    sort(cb = () => { }, type = "aircraft") {
        let tempList = this._tempList[this.date].list
        let result = []
        switch (type) {
            case 'aircraft':
                result = OrganizeAircraftList([{ data: tempList.sort(cb) }])
                break;

            case 'train':
                result = tempList.sort(cb)
                break;
        }
        this._tempList[this.date].list = result
        this.check++
    }

    // 下拉刷新当前列表
    resetList() {
        this._tempList[this.date] = {}
    }

}

// 获得实例
export function init() { return new CommonList }

// 获取机票列表ajax接口
function fetchAircraftList(date, query, corp) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        getFlightTicket(req).then(response => {
            let list = OrganizeAircraftList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 获取改签列表ajax接口
function fetchChangeAircraftList(date, query, corp) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        req.depDate = moment(req.depDate).format('YYYY-MM-DD')
        getChangeFlightTicket(req).then(response => {
            let list = OrganizeAircraftList([response])
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}





function fechTrainList(date, query, corp) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        getTrainTicket(req).then(response => {
            let list = OrganizeTrainList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

/**
 * 整理机票列表
 * @param {Array} arr 
 */
function OrganizeAircraftList(arr = []) {
    let dataArr = []
    arr.forEach(element => {
        let deadArr = []
        // 如果是数组遍历,过滤掉无座机票，保留无票坐席
        element.data = element.data.filter(ticket => {
            let arrtemp = ticket.flightSeat.filter(seat => seat.number != 0)
            if (!ticket.flightSeat || !(0 in arrtemp)) {
                ticket.canBuyMinPrice = ""
                deadArr.push(ticket)
                return false
            }
            return true

        })
        element.data.forEach(ticket => {
            let arrtemp = ticket.flightSeat.filter(seat => seat.number != 0)
            let min = ""
            if (0 in arrtemp)
                min = arrtemp.sort((a, b) => a.price.seatPrice - b.price.seatPrice)[0].price.seatPrice
            ticket.canBuyMinPrice = min.toString()
        })
        dataArr.push(...element.data, ...deadArr)
    });
    return dataArr
}

/**
 * 整理火车票列表
 * @param {Array} arr 
 */
function OrganizeTrainList(arr = []) {
    let dataArr = []
    arr.forEach(element => {
        try {
            element.data.map(item => {
                if (item.hasOwnProperty('displayPrice') && item.displayPrice == "") {
                    item.displayPrice = item.displaySeat[0].seatPrice
                }
                if (item.hasOwnProperty('arriveDays') && item.arriveDays == "") {
                    item.arriveDays = DiffDate(item.trainBeginDate + "T" + item.startTime, item.trainEndDate + "T" + item.arriveTime).toString()
                }
                return item
            })
            dataArr.push(...element.data)
        } catch (error) { }
    })
    return dataArr
}