<template>
  <!-- 航班列表的条目 -->
  <van-list
    v-model="loading"
    :finished="finished"
    :finished-text="this.list.length==0?'':'没有更多了'"
    @load="onLoad"
  >
    <div
      v-for="(ticketInfo,i) in showlist"
      :key="i"
      class="itembk"
      :class="ticketInfo.canBuyMinPrice==''?'cantbuy':''"
      @click="chooseTicket(ticketInfo)"
    >
      <van-row type="flex" justify="center">
        <van-row class="item-top" type="flex" justify="space-between" align="center">
          <van-col span="6">
            <van-row class="list-time">{{ticketInfo.departTime}}</van-row>
            <van-row class="info-text list-text van-ellipsis">{{ticketInfo.departAirport}}</van-row>
          </van-col>
          <van-col>
            <van-row
              class="list-text-big"
              style="transform: translateY(-0.4rem);"
            >{{ticketInfo.flightNo}}</van-row>
            <van-icon class="iconfont line" class-prefix="icon" name="icon_jipiaodancheng" />
          </van-col>
          <van-col>
            <van-row class="list-time">{{ticketInfo.arriveTime}}</van-row>
            <van-row class="info-text list-text van-ellipsis">{{ticketInfo.arriveAirport}}</van-row>
          </van-col>
          <van-col>
            <van-row>
              <span style="color: red;">￥</span>
              <span style="color: red;font-size: 1.2rem">{{ticketInfo.canBuyMinPrice||'-'}}</span>
              <span class="info-text list-text" style="color:rgb(172, 172, 172)">起</span>
            </van-row>
            <!--服务商标签-->
            <van-row type="flex" justify="center">
              <Logo :corp="ticketInfo.corp" />
            </van-row>
          </van-col>
        </van-row>
      </van-row>
      <template>
        <van-row type="flex" justify="center">
          <van-row class="item-bottom">
            <van-col>
              <van-image class="errhide" width="10" height="10" :src="ticketInfo.airlineLogoUrl">
                <template v-slot:error>
                  <van-icon
                    class="iconfont blue"
                    class-prefix="icon"
                    name="tuijianjipiao"
                    ref="change"
                  />
                </template>
              </van-image>
              &nbsp;{{ticketInfo.airlineCompany}}
            </van-col>
            <van-col span="1" style="text-align:center">|</van-col>
            <van-col>{{ticketInfo.flightModel}}</van-col>
            <van-col span="1" style="text-align:center">|</van-col>
            <van-col>{{ticketInfo.runTime}}</van-col>
          </van-row>
        </van-row>
      </template>
    </div>
  </van-list>
</template>

<script>
import Logo from "@/components/Logo";
export default {
  name: "TicketItem",
  props: {
    list: Array
  },
  data() {
    return {
      index: 0,
      pagesize: 20,
      showlist: [],
      loading: false,
      finished: false
    };
  },
  components: { Logo },
  methods: {
    // 选票
    chooseTicket(data) {
      if (data.canBuyMinPrice != "") this.$emit("childClick", data);
    },
    check() {
      if (this.showlist.length < this.list.length) this.finished = false;
    },
    onLoad() {
      setTimeout(() => {
        this.showlist.push(
          ...this.list.slice(this.index, this.index + this.pagesize)
        );
        this.index += this.pagesize;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.showlist.length >= this.list.length) {
          this.finished = true;
        }
      }, 300);
    }
  },
  watch: {
    list() {
      this.showlist = this.list.slice(0, this.index);
      if (this.showlist.length < this.pagesize && this.finished == false)
        this.onLoad();
    }
  }
};
</script>

<style lang="less" src="@/styles/itemCommon.less" scoped></style>
<style lang="less" scoped>
.listoutline {
  min-height: calc(100vh - 260px);
}
</style>
<style lang="less">
.errhide .van-image__error-icon {
  font-size: 10px;
}
</style>