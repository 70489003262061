<template>
  <!-- 顶部滑动日历组件，参数略多看下方注释 -->
  <div class="slider-cont">
    <div class="slider-x">
      <div v-for="(item,index) of dates" :key="index" @click="change(index, item)">
        <div :class="'basesize '+(active==index?'active':'')">
          <span
            v-if="0 in prices"
            class="date-text"
          >{{item | comverTime('MM-DD')}}&nbsp;|&nbsp;{{item | comverTime('ddd')}}</span>
          <span class="date-text" v-if="!(0 in prices)">{{item | comverTime('MM-DD')}}</span>
          <span class="week-text" v-if="!(0 in prices)">{{item | comverTime('ddd')}}</span>
          <span class="price" v-if="0 in prices">
            <font style="font-size:10px">￥</font>
            {{prices[index]||'-'}}
          </span>
        </div>
      </div>
      <div style="width:60px;height:1px"></div>
      <div class="calen-btn" @click="open">
        <van-icon name="calender-o" />
        <span class="date-text">日历</span>
      </div>
    </div>
  </div>
</template>

<script>
// 依赖
import moment from "moment";
import { DiffDate, AddDate } from "@/services/date.js";
export default {
  name: "sliderDate",
  props: {
    // 价格[number]，和起止日期一一对应
    prices: {
      type: Array,
      default: () => []
    },
    // 选中日期[Date]
    curDate: {
      default: () => new Date()
    },
    // 日期范围[Int],默认依照开始日期向后推29天
    range: {
      default: () => 29
    },
    // 开始日期[Date]
    start:{}
  },
  computed: {
    startDate() {
      let diff = DiffDate(new Date(), this.curDate);
      if (this.start) return this.start;
      else return AddDate(this.curDate, -(diff > 15 ? 15 : diff));
    },
    // 当前选项
    active: {
      get() {
        return Math.abs(
          moment(moment(this.startDate).format("YYYY-MM-DD")).diff(
            moment(moment(this.curDate).format("YYYY-MM-DD")),
            "days"
          )
        );
      },
      set(val) {
        return val;
      }
    },
    dates() {
      let i = this.range;
      let max = i;
      let arr = [];
      while (i >= 0) {
        arr.push(moment(this.startDate, "YYYY-MM-DD").add(max - i--, "days"));
      }
      return arr;
    }
  },
  data() {
    return {
      onload: false // 是否允许滚屏（对齐日期）
      // start: new Date()
    };
  },
  methods: {
    // 打开日历
    open() {
      this.$emit("open");
      this.onload = true;
    },
    // 触发父事件
    change(index, date) {
      if (index != this.active) {
        this.$emit("changeDate", new Date(date));
      }
    },
    // 滚屏
    scroll() {
      let domWidth = document.querySelector(".slider-x>div").offsetWidth;
      let offset = parseInt(this.active * domWidth);
      offset = offset - domWidth < 0 ? 0 : offset - domWidth;
      document.querySelector(".slider-x").scrollLeft = offset;
    }
  },
  mounted() {
    let diff = DiffDate(new Date(), this.curDate);
    this.scroll();
  },
  updated() {
    if (this.onload) {
      this.scroll();
      this.onload = false;
    }
  },
  activated() {
    this.scroll();
  }
};
</script>


<style lang="less" scoped>
@basesize: 50px; // 基准尺寸
.slider-cont {
  padding-right: @basesize+2px;
}
.slider-x {
  box-sizing: border-box;
  width: 100%;
  overflow: scroll;
  display: flex;
  padding: 1px 4px;
  div {
    width: auto;
    min-height: @basesize - 10px;
    padding: 2px 0 2px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    flex-direction: column;
    span {
      line-height: 12px;
      color: #fff;
      font {
        display: inline-block;
        margin-right: -4px;
      }
    }
    .week-text,
    .date-text {
      transform: scale(0.8);
      white-space: nowrap;
    }
    .price {
      padding-top: 2px;
    }
  }
  .active {
    background: #fff;
    span {
      line-height: 12px;
      color: #1989fa;
    }
  }
  .calen-btn {
    height: @basesize;
    min-width: @basesize;
    position: absolute;
    z-index: 2;
    background-color: #2d54d6;
    top: 0;
    right: 0;
    box-sizing: border-box;
    font-size: 24px;
    border-radius: 0;
    border-left: 1px solid;
    color: #fff;
    .date-text {
      font-size: 14px;
    }
  }
}
.basesize {
  min-width: @basesize;
}
.h {
  width: 44px;
  height: 1px;
}
</style>
